import React, {
  useContext,
  useEffect
} from "react";
import {
    Alert,
    Box,
    Button,
    Fade, TextField
} from "@mui/material";
import TransferNewAcc from "./TransferNewAcc";
import AccountSelector from "./AccountSelector";
import ReceiverAccInfo from "./ReceiverAccInfo";
import TransferInfo from "./TransferInfo";
import SimpleText from "../../../../../components/Shared/SimpleText";
import SimpleSelector from "../../../../../components/Shared/SimpleSelector";
import Context from "../../../../../contexts/Context";
import Masks from "../../../../../components/Shared/Masks";

const Pix = ({
    setAccount,
    account,
    checkingAccounts,
    selectedAccount,
    setSelectedAccount,
    bankNames,
    codeSent,
    check,
    setCheck,
    reqData,
    setReqData,
    submitReqPix,
    buttonBehaviorPix,
  }) => {

  const { filters, sessionCheck } = useContext(Context)

  const {
    operationType
  } = filters || {}


  const pixType = {
    "CPF": 0,
    "CNPJ": 1,
    "E-mail": 2,
    "Celular": 3,
    ["Chave aleatória"]: 4,
  };

    const cpfChecker = (cpf) => {
        let sum;
        let rest;
        sum = 0;
        if (cpf === "00000000000") return false;

        for (let i = 1; i <= 9; i++) {
            sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
        }
        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11)) rest = 0;
        if (rest !== parseInt(cpf.substring(9, 10)) ) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
        }
        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11)) rest = 0;
        if (rest !== parseInt(cpf.substring(10, 11) )) return false;
        return true;
    }

    const formatPixKeyDisplay = (pixKey) => {
        let cleanedPixKey = pixKey?.replace(/[^a-zA-Z0-9]/g, "");

        if (cleanedPixKey && !(/[a-zA-Z]/g.test(cleanedPixKey))){
            if(cleanedPixKey?.length === 11){
                return cpfChecker(cleanedPixKey) ? Masks.cpf(pixKey ?? '') : Masks.phone(pixKey ?? '');
            }
            else if (cleanedPixKey?.length === 14){
                return Masks.cnpj(pixKey ?? '');

            }

        } else  if (!pixKey?.replace(/[^a-zA-Z0-9]/g, "") && cleanedPixKey?.substring(0, 11) && (/^\d+$/.test(cleanedPixKey))) {
            return Masks.phone(pixKey ?? '');
        }


        return pixKey
    };

  const  pixKeyArray = Object.keys(pixType).map((pix) => {
    return { type: pix, value: pixType[pix] };
  });


  useEffect(function setForReq(){
    setReqData(prevState => ({...prevState, pix_key: null}))
  },[])

    function handlePix(event) {
        setReqData(prevState => ({...prevState, pix_key: event.target.value}))
    }

  return(
    <>
      <TransferNewAcc
        check={check}
        setCheck={setCheck}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        {!check &&
          <AccountSelector
            account={account}
            setAccount={setAccount}
            checkingAccounts={checkingAccounts}
          />
        }
      </Box>
      <ReceiverAccInfo
        selectedAccount={selectedAccount}
        bankNames={bankNames}
        codeSent={codeSent}
        account={account}
        setSelectedAccount={setSelectedAccount}
        setAccount={setAccount}
      />
        {check &&
        <TextField
            label="Chave Pix"
            fullWidth
            size={'small'}
            data={reqData}
            setData={setReqData}
            keyName={'pix_key'}
            value={formatPixKeyDisplay(reqData?.pix_key)}
            onChange={handlePix}
            sx={{mb: '1rem'}}
        />
        }

        <TransferInfo
        reqData={reqData}
        setReqData={setReqData}
      />


      <SimpleText
        label={'Descrição'}
        fullwidth
        state={reqData}
        setState={setReqData}
        keyName={'description'}
        multiline={true}
        rows={5}
      />

      {filters?.bankTransfer &&
        <Alert
          variant={'outlined'}
          severity="info"
          sx={{
            border: 'none',
            marginY: '1rem',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          É necessário validar para salvar a despesa
        </Alert>
      }


      {operationType === 'transfer' &&
        <>
          <Button
            onClick={() => {
              sessionCheck()
              if (check) {
                submitReqPix()
              }
            }}
            variant={'contained'}
            disabled={check ? !buttonBehaviorPix?.isEnabled : !buttonBehaviorPix?.isEnabledJustTransfer}
            sx={{
              mt: '1rem',
              width: '100%'
            }}
          >
            {'Validar'}
          </Button>
        </>
      }
    </>
  )
}

export default Pix