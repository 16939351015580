import React, {
  useContext,
  useState
} from "react";
import {
  Box,
  Fade,
  IconButton }
  from "@mui/material";
import { Delete } from "@mui/icons-material";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import Masks from "../../../../../components/Shared/Masks";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import {api} from "../../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import Context from "../../../../../contexts/Context";

const ReceiverAccInfo =
  ({
    selectedAccount,
    setSelectedAccount,
    bankNames,
    codeSent,
    account,
    setAccount
  }) => {

  const [openDelete, setOpenDelete] = useState(false)

  const { setDataProvider, dataProvider, filters } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()

  function title() {
    if (filters?.transferType === 'ted'){
      return 'Deletar Conta Bancária'
    } else {
      return 'Deletar Chave Pix'
    }

  }

  function question(){
    if (filters?.transferType === 'ted'){
      return 'Gostaria realmente de apagar esta Conta Bancária?'
    } else {
      return 'Gostaria realmente de apagar esta Chave Pix?'
    }

  }

  async function deleteAcc() {
    try {
      const response = filters?.transferType === 'ted'
        ? await api.delete(`fitbank/external-bank/${selectedAccount?.id}`)
        : filters?.transferType === 'pix' &&
        await api.delete(`fitbank/pix-key/${selectedAccount?.id}`);

      if (response) {
        if (filters?.transferType === 'ted') {
          enqueueSnackbar('Conta bancária deletada', { variant: 'success' });
        } else if (filters?.transferType === 'pix') {
          enqueueSnackbar('Chave Pix deletada', { variant: 'success' });
        }

        setSelectedAccount(null);
        setAccount(null);

        setDataProvider((prevState) => ({
          ...prevState,
          refresh: !dataProvider?.refresh,
        }));
      }
    } catch (error) {
      enqueueSnackbar('Erro ao deletar conta bancária', { variant: 'error' });
      console.log(error);
    }
  }




    return (
    <>
      <Fade
        in={!!selectedAccount && !codeSent }
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            bgcolor: 'neutral.main',
            p: '1rem',
            borderRadius: '1rem',
            mb: '1rem',
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >


          <SimpleTypography
            label={account?.search}
            isLoading={!selectedAccount}
            labelStyles={{
              color: 'primary.main',
              fontWeight: 'bold',
              fontSize: '1.2rem'
            }}
            boxStyles={{
              mb: '0'
            }}
          />
          <IconButton
            onClick={() => setOpenDelete(true)}
          >
            <Delete />
          </IconButton>
          </Box>

          {selectedAccount?.pix_key_value &&
            <SimpleTypography
              label={'Chave'}
              isLoading={!selectedAccount}
              directValue={
                selectedAccount?.pix_key_type === '0' ? Masks?.cpf(selectedAccount?.pix_key_value?.toString() ?? '') :
                selectedAccount?.pix_key_type === '1' ? Masks?.cnpj(selectedAccount?.pix_key_value?.toString() ?? '') :
                selectedAccount?.pix_key_type === '3' ? Masks?.phone(selectedAccount?.pix_key_value?.toString() ?? '') :
                selectedAccount?.pix_key_value?.toString() ?? ''

              }
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          <SimpleTypography
            label={'Banco'}
            isLoading={!selectedAccount}
            directValue={bankNames?.find((e) => e?.code === selectedAccount?.bank)?.name}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '1rem'
            }}
          />

          {/*<SimpleTypography*/}
          {/*  label={'Tipo'}*/}
          {/*  isLoading={!selectedAccount}*/}
          {/*  directValue={selectedAccount?.account_type_label ?? selectedAccount?.bank_account_type_label}*/}
          {/*  labelStyles={{*/}
          {/*    fontWeight: 'bold'*/}
          {/*  }}*/}
          {/*  boxStyles={{*/}
          {/*    width: '100%',*/}
          {/*    mb: '1rem'*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<SimpleTypography*/}
          {/*  label={'Agência'}*/}
          {/*  isLoading={!selectedAccount}*/}
          {/*  directValue={`${selectedAccount?.bank_branch} ${selectedAccount?.bank_branch_digit ? `- ${selectedAccount?.bank_branch_digit}`  : ''}`}*/}
          {/*  labelStyles={{*/}
          {/*    fontWeight: 'bold'*/}
          {/*  }}*/}
          {/*  boxStyles={{*/}
          {/*    width: '100%',*/}
          {/*    mb: '1rem'*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<SimpleTypography*/}
          {/*  label={'Conta'}*/}
          {/*  isLoading={!selectedAccount}*/}
          {/*  directValue={` ${selectedAccount?.bank_account} - ${selectedAccount?.bank_account_digit} `}*/}
          {/*  labelStyles={{*/}
          {/*    fontWeight: 'bold'*/}
          {/*  }}*/}
          {/*  boxStyles={{*/}
          {/*    width: '100%',*/}
          {/*    mb: '1rem'*/}
          {/*  }}*/}
          {/*/>*/}



        </Box>
      </Fade>
      <SimpleDialog
        title={title()}
        open={openDelete}
        setOpen={setOpenDelete}
        question={question()}
        functionProp={deleteAcc}
      />
    </>
  )
}

export default ReceiverAccInfo