import {
    Box,
    Skeleton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Button,
    Menu,
    MenuItem,
    CircularProgress, DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { api } from "../../../services/Main/Api";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TableCell from "@mui/material/TableCell";
import Masks from "../../../components/Shared/Masks";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

const DimobDetail = ({ item, year, response, handleCloseDialog, loading, setLoading, setIsSuccess, isSuccess }) => {
    const [details, setDetails] = useState(null);
    const [editedDetails, setEditedDetails] = useState(0);
    const [submitting, setSubmitting] = useState(false); // Estado para controlar o envio
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

// Função para abrir o menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

// Função para fechar o menu
    const handleClose = () => {
        setAnchorEl(null);
    };
    const fetchDetails = () => {
        if (item?.id) {
            setLoading(true);
            const unit_id = item?.unit?.id;
            const recipient_id = item?.recipient?.id;
            const responseId = item?.id;
            console.log('responseId', responseId)
            api.get(`/dimob/company/${responseId}`, { params: { year, unit_id, recipient_id } }).then((res) => {
                setDetails(res.data);
                setEditedDetails(res.data); // Copia inicial para edição
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [item, year]);

    const handleUpdate = () => {
        if (editedDetails) {
            setLoading(true);
            const unit_id = item?.unit?.id;
            const responseId = item?.id;
            const url = `/dimob/company/${responseId}`;

            api.put(url, editedDetails, )
                .then((res) => {
                    console.log('Update Successful');
                    setDetails(res.data);
                    setEditedDetails(res.data);
                    setIsSuccess(true);
                    handleCloseDialog()
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                }).finally(data => {
                setTimeout(() => {
                    setLoading(false)
                    setIsSuccess(false)
                }, 3000);

            })
        }
    }

        const handleInputChange = (field, index, value) => {
        let asInt = 0;

        if (value) {
            asInt = parseInt(value.replace(/\D/g,''), 10);
        }

        const updatedResults = [...editedDetails.results];
        updatedResults[index][field] = asInt;
        setEditedDetails({ ...editedDetails, results: updatedResults });
    };

    if (loading) {
        return <Skeleton variant="rectangular" height={400} />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2}}>
                <Typography variant="h6">
                    Dados Dimob
                </Typography>
                <div>
                    <Button size={'small'} variant="outlined" onClick={handleClick}>
                        Editar
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose} component={Link} to={`/alugueis/imoveis/${item?.unit?.id}/editar`}>Editar Locatário</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleClose} component={Link} to={`/beneficiarios/${item?.recipient?.id}`}>Editar Locador</MenuItem>
                    </Menu>
                </div>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "2rem" }}>
                <Box>
                    <Typography variant="subtitle1" gutterBottom>Dados do Locatário</Typography>
                    <Typography>CPF/CNPJ: {details?.unit?.cpf}{details?.unit?.cpf?.length < 14 ? Masks.cpf(details?.unit?.cpf ?? '') : Masks.cnpj(details?.unit?.cpf ?? '')}</Typography>
                    <Typography>Nome: {details?.unit?.name}</Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle1" gutterBottom>Dados do Locador</Typography>
                    <Typography>CPF/CNPJ: {details?.recipient?.cpf_cnpj?.length < 14 ? Masks.cpf(details?.recipient?.cpf_cnpj ?? '') : Masks.cnpj(details?.recipient?.cpf_cnpj ?? '')}</Typography>
                    <Typography>Nome: {details?.recipient?.name}</Typography>
                </Box>
            </Box>

            {/* Endereço */}
            <Box sx={{ marginBottom: "2rem" }}>
                <Typography variant="subtitle1" gutterBottom>Endereço</Typography>
                <Typography>Rua: {details?.unit?.street}, {details?.unit?.number}</Typography>
                <Typography>Bairro: {details?.unit?.district}</Typography>
                <Typography>Cidade: {details?.unit?.city}, {details?.unit?.state}</Typography>
                <Typography>CEP: {Masks.cep(`${details?.unit?.cep}`)}</Typography>
            </Box>

            {/* Tabela de Resultados */}
            <TableContainer>
                {editedDetails?.results?.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Mês</TableCell>
                                <TableCell align="center">Aluguel</TableCell>
                                <TableCell align="center">Comissão</TableCell>
                                <TableCell align="center">Imposto Retido</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {editedDetails.results.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{detail.month}</TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            fullWidth
                                            value={detail.rent_value && Masks.money(detail?.rent_value.toString())}
                                            onChange={(e) => handleInputChange("rent_value", index, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            fullWidth
                                            value={detail.administrative_value && Masks.money(detail?.administrative_value.toString())}
                                            onChange={(e) => handleInputChange("administrative_value", index, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            fullWidth
                                            value={detail.irrf_fee && Masks.money(detail?.irrf_fee.toString())}
                                            onChange={(e) => handleInputChange("irrf_fee", index, e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <NoResultsAnimation />
                )}
            </TableContainer>
            <Box display={'flex'} justifyContent={'space-between'} mt={2} mx={2}>
                <Button variant="text" color={"secondary"} onClick={handleCloseDialog}>Cancelar</Button>
                <Button variant="contained" color={"primary"} onClick={handleUpdate}>{"Salvar"}</Button>
            </Box>
        </Box>
    );
};

export default DimobDetail;
