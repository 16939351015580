import {
  TextField,
  IconButton,
  List,
  ListItem,
  Button,
  Box,
  CardHeader,
  Avatar,
  Divider,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Typography, Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AccountBalance,
  Add,
  Edit, Pix, Savings,
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import BankSelector from "../BankOperations/components/BankTransfer/BankSelector";
import {getBanks} from "../BankOperations/components/BankTransfer/Requisitions";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Context from "../../../contexts/Context";
import SaveCancelButton from "../../../components/Shared/SaveCancelButton";

function DataInput(props) {
  const params = useParams()

  const { dataProvider, sessionCheck } = useContext(Context)

  const { index, onChange, onRemove, id, accUpdate, accCreate, deleteAccById, getAcc, ...dataProps } = props;
  const { bank, bank_branch, bank_account, bank_account_digit, bank_account_type, bank_branch_digit } = dataProps
  const [handleId, setHandleId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [bankNames, setBankNames] = useState(null)
  const [bankData, setBankData] = useState(null)
  const [edit, setEdit] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handleBankBranchChange (event) {
    onChange(index, { ...dataProps, bank_branch: event.target.value, id,  })
  }

  function handleBranchDigitChange (event) {
    onChange(index, { ...dataProps, bank_branch_digit: event.target.value, id,  })
  }

  function handleBankAccountChange (event) {
    onChange(index, { ...dataProps, bank_account: event.target.value, id })
  }

  function handleBankDigitChange (event) {
    onChange(index, { ...dataProps, bank_account_digit: event.target.value, id })
  }

  function handleAccountTypeChange  (value) {
    onChange(index, { ...dataProps, bank_account_type: value, id })
  }

  function returnAccType(param) {
    switch (param) {
      case 0:
        return 'Corrente';
      case 1:
        return 'Poupança';
      default:
        return '- - -';
    }
  }

  function handleEdit(){
    setEdit(!edit)
  }

  function branchBranchAndDigit(){
    if (bank_branch_digit){
      return `${bank_branch} - ${bank_branch_digit}`
    } else {
      return bank_branch
    }
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function bankNames(){
    getBanks(setBankNames)
  }, [])

  useEffect(function setBank(){
    if (bankData) {
      onChange(index, {...dataProps, bank: bankData, id})
    }
  }, [bankData])

  const bankOptions = bankNames?.map((option, index) => ({ name: option.name , code: option.code, key: index}))

  useEffect(function observeInputs() {
    const requiredKeys = ['bank_branch', 'bank_account', 'bank_account_digit', 'bank', 'bank_account_type'];
    const allKeysHaveValue = requiredKeys.every(key => !!dataProps?.[key]?.toString().trim());

    if (allKeysHaveValue) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [dataProps]);

  return (
    <>

      <Box
        sx={{
          display: 'flex',
          borderRadius: '1rem',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >

        { id && !edit ?
          <Box
            sx={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >

            <Box
              sx={{
                paddingX: '2rem',
                paddingTop: '0.5rem',
                mb: '0.3rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: '18px'
                    }}
                  >
                    {bank?.code} - {bank?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '1rem'

                  }}
                >

                  <IconButton onClick={() => {
                    handleEdit()
                    setHandleId(id)
                    sessionCheck()
                  }}>
                    <Edit />
                  </IconButton>


                  <IconButton onClick={() => {
                    setHandleId(id)
                    setOpenDelete(true)
                    sessionCheck()
                  }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/*<Divider sx={{mb: '1rem'}}/>*/}

            <Box
              sx={{
                paddingX: '2rem',
                // paddingBottom: '2rem'
                mb: '1rem'
              }}
            >
              <SimpleTypography
                label={'Agência'}
                directValue={branchBranchAndDigit()}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />


              <SimpleTypography
                label={`Conta ${returnAccType(bank_account_type)}`}
                directValue={`${bank_account} - ${bank_account_digit} ` }
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />


            </Box>
          </Box>

          :
          <>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                // maxWidth: '700px',
                paddingX: '2rem'
              }}
            >

              { id ?
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '1rem'
                  }}
                >
                  <Box>
                    {!edit &&
                      <Typography
                        sx={{
                          color: 'primary.main',
                          fontWeight: 'bold',
                          fontSize: '23px'
                        }}
                      >
                        {bank?.name}
                      </Typography>
                    }
                  </Box>

                    <SaveCancelButton
                      edit={edit}
                      disabled={!isSaveEnabled}
                      saveClick={() => {
                        accUpdate(params.id , handleId)
                        setHandleId(null)
                      }}
                      cancelClick={() => {
                        getAcc(null, true)
                        handleEdit()
                        setHandleId(null)
                      }}

                    />


                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{
                      // mb: '1rem',
                      color: 'primary.main'
                    }}
                  >
                    { !id ? 'Nova conta' : 'Editar conta' }
                  </Typography>
                  {!id &&
                    <SaveCancelButton
                      edit={!edit}
                      disabled={!isSaveEnabled}
                      saveClick={() => {
                        accCreate(params.id ?? dataProvider?.recipientId)
                      }}
                      cancelClick={() => onRemove(index)}
                    />
                  }
                </Box>
              }
                <BankSelector
                  bankNames={bankNames}
                  reqData={bankData}
                  setReqData={setBankData}
                  defaultValue={bankOptions?.find(e => e.code === bank.code)}
                />


              <Box
                sx={{
                  display: 'flex',
                }}

              >

                <TextField
                  label="Agência"
                  size={'small'}
                  disabled={id && handleId !== id}
                  value={bank_branch?.toString()?.replace(/\D+/g, "") ?? ''}
                  onChange={handleBankBranchChange}
                  sx={{
                    maxWidth: '400px',
                    width: '100%',
                    mr: '0.5rem'
                  }}
                />
                {bankData?.bank === '001' &&
                <TextField
                  label="Dígito"
                  size={'small'}
                  disabled={id && handleId !== id}
                  value={bank_branch_digit?.toString()?.replace(/\D+/g, "") ?? ''}
                  onChange={handleBranchDigitChange}
                  sx={{
                    maxWidth: '400px',
                    width: '100%',
                    mr: '0.5rem'
                  }}
                />
                }
                <TextField
                  label="Conta"
                  disabled={id && handleId !== id}
                  size={'small'}
                  value={bank_account?.toString()?.replace(/\D+/g, "") ?? ''}
                  onChange={handleBankAccountChange}
                  sx={{
                    maxWidth: '400px',
                    width: '100%',
                    mr: '0.5rem',
                    mb: '1rem'
                  }}
                />

                <TextField
                  label="Dígito"
                  disabled={id && handleId !== id}
                  size={'small'}
                  value={bank_account_digit?.toString()?.replace(/\D+/g, "") ?? ''}
                  onChange={handleBankDigitChange}
                  sx={{
                    maxWidth: '100px',
                    width: '100%',
                    mb: '1rem'
                  }}
                />
              </Box>

              <ToggleButtonGroup
                fullWidth
                exclusive
                value={bank_account_type}
                color={'primary'}
                onChange={(_e,value) => handleAccountTypeChange(value)}
                sx={{mb: '1rem'}}
              >
                <ToggleButton
                  value={0}
                  selected={bank_account_type === 0}
                >
                  Corrente
                </ToggleButton>
                <ToggleButton
                  value={1}
                  selected={bank_account_type === 1}
                >
                  Poupança
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </>
        }
        <SimpleDialog
          open={openDelete}
          setOpen={setOpenDelete}
          title={'Excluir conta'}
          question={'Realmente quer excluir essa conta?'}
          onClickConfirm={() => deleteAccById(handleId)}
        />
      </Box>
    </>
  );
}

function RentRecipientAccountSet(props) {
  const { dataProvider } = useContext(Context)
  const { data, onChange, setDataDeleteAcc, isLoading, accUpdate, accCreate, deleteAccById, getAcc } = props;

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handleDataChange (index, newData) {
    const newDataList = [...data];
    newDataList[index] = newData;
    onChange(newDataList);
  };

  function handleDataRemove (index) {
    const newDataList = data.filter((_, i) => i !== index);
    const newDataDelete = data.filter((_, i) => i === index);
    const objToDelete = newDataDelete.find((obj) => obj.hasOwnProperty("id"));
    if (objToDelete) {
      setDataDeleteAcc(prevState => [...prevState, objToDelete]);
    }
    onChange(newDataList);
  };

  function handleAddData () {
    const newDataList = [
      ...data, {
        bank: {name:'', code: ''},
        bank_branch: '',
        bank_branch_digit: '',
        bank_account: '',
        bank_account_digit: '',
        account_type: 'TED'
      }
    ];
    onChange(newDataList);
  }
  //
  // useEffect(function addFormSet(){
  //   handleAddData()
  // }, [dataProvider?.recipientAcc])

  const dataFind = data?.find(e => e.id && e.account_type === 'TED')

  return (
    <>
      <Box
        sx={{
          paddingX: '2rem',
          mt: '2rem'
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: '20px',
            mb: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AccountBalance
            sx={{mr: '0.5rem'}}
          />
          Contas Bancárias
        </Typography>
        <Divider sx={{mb: '0.5rem'}}/>
      </Box>

      {!dataFind &&
        <Box
          sx={{
            marginY: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Alert
            severity="warning"
          >
            Não há nenhuma conta bancária cadastrada
          </Alert>
        </Box>
      }


      <List>
        {data?.map((entry, index) => {
          if (entry.account_type === 'TED') {
            return (
              <ListItem key={index} disableGutters>
                <DataInput
                  index={index}
                  id={entry.id}
                  bank={entry.bank}
                  bank_branch={entry.bank_branch}
                  bank_account={entry.bank_account}
                  bank_branch_digit={entry.bank_branch_digit}
                  bank_account_digit={entry.bank_account_digit}
                  bank_account_type={entry?.bank_account_type}
                  account_type={entry.account_type}
                  onChange={handleDataChange}
                  onRemove={handleDataRemove}
                  deleteAccById={deleteAccById}
                  accUpdate={accUpdate}
                  accCreate={accCreate}
                  getAcc={getAcc}
                />
              </ListItem>
            );
          }
        })}
      </List>
    </>

  );
}

export default RentRecipientAccountSet