import {
    Alert,
    Avatar,
    Backdrop,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader, Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper, Popover,
    Skeleton,
    Table, TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    DescriptionOutlined,
    FileCopyOutlined,
    ForwardToInboxOutlined, Lock, NotificationsOutlined,
    Receipt, UploadFile
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import { api } from "../../../services/Main/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getCondoId } from '../../../services/Main/AuthStorage'
import Context from "../../../contexts/Context";
import Branding from "../User/Branding";
import CondoInfo from "../Condo/components/CondoUpdate/CondoInfo/CondoInfo";
import CondoSpecs from "../Condo/components/CondoUpdate/CondoSpecs/CondoSpecs";
import CondoChargeConfig from "../Condo/components/CondoUpdate/CondoChargeConfig/CondoChargeConfig";
import Masks from "../../../components/Shared/Masks";
import { useDropzone } from 'react-dropzone';
import TableCell from "@mui/material/TableCell";
import moment from "moment/moment";

const LimitBankDetail = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { ownerOptions, getOwnerOptions } = useContext(Context)
    const params = useParams()
    const {id} = params
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [dataRequest, setDataRequest] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [duplicateData, setDuplicateData] = useState(null)
    const [edit, setEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const companyId = getCondoId()
    const sizeMatch = useMediaQuery('@media (min-width:600px)')
    const [open, setOpen] = useState(false);
    const [newValueDaily, setNewValueDaily] = useState( null);
    const [newValueMonth, setNewValueMonth] = useState( null);
    const [newValueTransaction, setNewValueTransaction] = useState( null);
    const [newValueOwnerShip, setNewValueOwnerShip] = useState( null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [openRequest, setOpenRequest] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [processingDate, setProcessingDate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = Boolean(anchorEl);
    const { user } = useContext(Context);
    const { is_superuser } = user || {}
    const handleFilesChange = (files) => {
        setSelectedFiles(oldFiles => [...oldFiles, ...files]);
        const names = files.map((file) => file.name);
        setFileNames(oldNames => [...oldNames, ...names]);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handlePopoverOpen = (event, item) => {
        if (item.status === "Processado") {
            setAnchorEl(event.currentTarget);
            setProcessingDate(item.processing_date);
        }
    };
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }


    const handleClose = () => {
        setOpen(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf/*',
        onDrop: (acceptedFiles) => {
            handleFilesChange(acceptedFiles);
        },
        multiple: true
    });

    async function fetchDataRequest() {
        setIsLoading(true)
        try {
            const response = await api.get(`/fitbank/limit/request/?company_id=${companyId}`);
            setDataRequest(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    async function fetchData() {
        setIsLoading(true)
        try {
            const response = await api.get(`/fitbank/limit-bank/${companyId}/`);
            setData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const postIncreaseLimitRequest = async (value, files) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('limit_value_daily', newValueDaily.replace(/[^0-9]/g, ''));
        formData.append('limit_value_month', newValueMonth.replace(/[^0-9]/g, ''));
        formData.append('limit_value_transaction', newValueTransaction.replace(/[^0-9]/g, ''));
        formData.append('limit_value_ownership', newValueOwnerShip.replace(/[^0-9]/g, ''));
        files.forEach((file) => {
            formData.append('document_file', file);
        });
        formData.append('company', companyId);
        try {
            await api.post(`fitbank/limit/request/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setIsLoading(false)
            enqueueSnackbar('Sucesso ao enviar sua solicitação!', { variant: 'success' });
            fetchData();
            fetchDataRequest()
        } catch (error) {
            setIsLoading(false)
            const errorMessage = error.response?.data[0] || 'Erro ao enviar sua solicitação!';
            console.log(error.response.data[0], error);
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    }

    const onSubmit = () => {
        if (selectedFiles.length && newValueDaily && newValueMonth && newValueTransaction && newValueOwnerShip) {
            postIncreaseLimitRequest(
                newValueDaily, selectedFiles);
            handleClose()
        } else {
            console.error('Value ou selectedFiles não foram definidos');
        }
    }

    let message = "";
    if (data?.limit_processing) {
        message = `Já existe uma solicitação de aumento de limite em análise.
          é necessario aguardar a conclusão para uma nova solicitação`;
    }
    else {
        message = "Por favor, digite o valor desejado para o aumento do limite."
    }

    useEffect(function pageLoad(){
        fetchData()
        fetchDataRequest()
    },[])

    let value = newValueDaily;
    value = value?.replace("R$", "").replace(/\./g, "").replace(",", "").trim();
    let integerValue = parseInt(value, 10);
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, }}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box>
                <Card>
                    {!isLoading ?
                        <>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: 'primary.main'
                                        }}>
                                        <AccountBalanceOutlinedIcon/>
                                    </Avatar>}
                                title={'Limite Bancário'}
                                action={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size={"small"}
                                        sx={{mt:1, mr: 1}}
                                        onClick={handleClickOpen}>
                                        Solicitar Limite
                                    </Button>
                                }
                            />
                            <Divider sx={{ my: '.5rem' }}/>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2} mb={'1.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="left">LIMITE</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="center">PIX</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontWeight="bold" align="center">TED</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Diário</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.daily_limit_pix && data.daily_limit_pix > 0
                                                ? Masks?.money(data.daily_limit_pix.toString())
                                                : '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.daily_limit_ted && data.daily_limit_ted > 0
                                                ? Masks?.money(data.daily_limit_ted.toString())
                                                : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Mensal</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">{data?.monthly_limit_pix && data.monthly_limit_pix > 0
                                                ? Masks?.money(data.monthly_limit_pix.toString())
                                                : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.monthly_limit_ted && data.monthly_limit_ted > 0
                                                    ? Masks?.money(data.monthly_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Noturno</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.overnigth_limit_pix && data.overnigth_limit_pix > 0
                                                    ? Masks?.money(data.overnigth_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.overnigth_limit_ted && data.overnigth_limit_ted > 0
                                                    ? Masks?.money(data.overnigth_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Por Transação</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.transaction_limit_pix && data.transaction_limit_pix > 0
                                                    ? Masks?.money(data.transaction_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.transaction_limit_ted && data.transaction_limit_ted > 0
                                                    ? Masks?.money(data.transaction_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mb={'.5rem'}>
                                        <Grid item xs={4}>
                                            <Typography>Mesma Titularidade</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.same_ownership_limit_pix && data.same_ownership_limit_pix > 0
                                                    ? Masks?.money(data.same_ownership_limit_pix.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="center">
                                                {data?.same_ownership_limit_ted && data.same_ownership_limit_ted > 0
                                                    ? Masks?.money(data.same_ownership_limit_ted.toString())
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={700}
                            animation="pulse"
                        />
                    }

                </Card>

            </Box>
            <Box sx={{mt:'1rem'}}>
                <Card>
                    {!isLoading ?
                        <>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: 'primary.main'
                                        }}>
                                        <CurrencyExchangeOutlinedIcon/>
                                    </Avatar>}
                                title={'Pedidos'}
                            />
                            <Divider sx={{ my: '.5rem' }}/>
                            <Card>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Numero</TableCell>
                                                <TableCell align="center">Data/Hora</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">Usuario</TableCell>
                                                <TableCell align="center">Documento</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataRequest?.results?.map((item) => (
                                                <TableRow key={item.id}
                                                          onClick={() => {
                                                              setSelectedItem(item);
                                                              setOpenRequest(true);
                                                          }}>
                                                    <TableCell align="center">{item.number}</TableCell>
                                                    <TableCell align="center">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                    <TableCell align="center">
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContet: 'center',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Chip
                                                                onMouseEnter={(event) => handlePopoverOpen(event, item)}
                                                                onMouseLeave={handlePopoverClose}
                                                                color={item.status === 'Processando' ? 'warning' :
                                                                    item.status === 'Processado' ? 'success' :
                                                                        item.status === 'Reprovado' ? 'error' : 'info'
                                                                }
                                                                size="small"
                                                                variant="outlined"
                                                                label={item.status}/>
                                                            {openPopover &&
                                                                <Popover
                                                                    id="paid-at-popover"
                                                                    sx={{
                                                                        pointerEvents: 'none',
                                                                    }}
                                                                    open={openPopover}
                                                                    anchorEl={anchorEl}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    onClose={handlePopoverClose}
                                                                    disableRestoreFocus
                                                                >
                                                                    <Typography fontSize={'small'} sx={{ p: 1 }}>Processado em { moment(processingDate).format('DD/MM/YYYY') } </Typography>                                                            </Popover>
                                                            }
                                                            <Typography
                                                                sx={{
                                                                    mt: '0.3rem',
                                                                    fontSize: '0.7rem'
                                                                }}
                                                            >
                                                            </Typography>
                                                        </Box>

                                                    </TableCell>
                                                    <TableCell align="center">{item.user}</TableCell>
                                                    <TableCell align="center">
                                                        {item.document_file &&
                                                            <a href={item.document_file} target="_blank" rel="noopener noreferrer">
                                                                <PictureAsPdfIcon color="disabled"/>
                                                            </a>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                            <Dialog open={open} onClose={handleClose} data={data}>
                                <DialogTitle>Solicitar Aumento de Limite</DialogTitle>
                                <Divider sx={{ mt: '.5rem' }}/>
                                <DialogContent sx={{maxWidth: '500px', minWidth: '500px'}}>
                                    {message &&
                                        <Alert
                                            severity={'info'}
                                            sx={{
                                                mb: '2rem',
                                            }}
                                        >
                                            {message}
                                        </Alert>
                                    }
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        id="value"
                                        label="Valor Solicitado para Limite Diario"
                                        placeholder={newValueDaily}
                                        onChange={({target: {value}}) => setNewValueDaily(value)}
                                        value={newValueDaily ? Masks.money(String(newValueDaily)) : ""} sx={{
                                        mb: '2rem'
                                    }}
                                    />
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        id="value"
                                        label="Valor Solicitado para Limite Mensal"
                                        placeholder={newValueMonth}
                                        onChange={({target: {value}}) => setNewValueMonth(value)}
                                        value={newValueMonth ? Masks.money(String(newValueMonth)) : ""} sx={{
                                        mb: '2rem'
                                    }}
                                    />
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        id="value"
                                        label="Valor Solicitado para Limite por transação"
                                        placeholder={newValueTransaction}
                                        onChange={({target: {value}}) => setNewValueTransaction(value)}
                                        value={newValueTransaction ? Masks.money(String(newValueTransaction)) : ""} sx={{
                                        mb: '2rem'
                                    }}
                                    />
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        id="value"
                                        label="Valor Solicitado para Limite de Mesma Titularidade"
                                        placeholder={newValueOwnerShip}
                                        onChange={({target: {value}}) => setNewValueOwnerShip(value)}
                                        value={newValueOwnerShip ? Masks.money(String(newValueOwnerShip)) : ""} sx={{
                                        mb: '2rem'
                                    }}
                                    />
                                    <Box
                                        {...(data?.new_limit ? {} : getRootProps())}
                                        sx={{
                                            border: '2px dotted gray',
                                            borderRadius: 1,
                                            padding: '10px',
                                            textAlign: 'center',
                                            marginTop: '10px',
                                            cursor: 'pointer',
                                            backgroundColor: '#f3f3f3'
                                        }}>
                                        <input {...getInputProps()} />
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {!fileNames.length ? (
                                                <>
                                                    <UploadFile sx={{color: 'gray'}}/>
                                                    <p style={{color: 'gray'}}>Comprovante de Faturamento</p>
                                                </>
                                            ) : (
                                                <p style={{color: 'gray'}}>{fileNames.join(', ')}</p>
                                            )}
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
                                    <Button
                                        onClick={handleClose}
                                        size={"small"}
                                        color="primary">
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={
                                            !newValueDaily || !newValueMonth || !newValueTransaction
                                            || !newValueOwnerShip || !fileNames || !fileNames.length
                                        }
                                        onClick={onSubmit}
                                        size={"small"}
                                        color={"primary"}
                                    >
                                        Solicitar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={openRequest}
                                onClose={() => setOpenRequest(false)}
                                fullWidth={true}
                                maxWidth="md"
                            >
                                <DialogTitle id="form-dialog-title">Detalhes do Pedido</DialogTitle>
                                <Divider></Divider>
                                <DialogContent>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Limite</TableCell>
                                                <TableCell align="center">Tipo</TableCell>
                                                <TableCell align="center">Valor</TableCell>
                                                <TableCell align="center">Data/Hora</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">Usuário</TableCell>
                                                {is_superuser &&
                                                    <TableCell align="center"></TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedItem?.requests?.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell component="th" scope="row">{item.limit_type}</TableCell>
                                                    <TableCell align="center">{item.operation_type}</TableCell>
                                                    <TableCell align="center">
                                                        {item.limit_value
                                                            ? Masks?.money(item.limit_value.toString())
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell align="center">{moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                    <TableCell align="center">
                                                        <Chip
                                                            color={item.status_bank === 'Processando' ? 'warning' :
                                                                item.status_bank === 'Aprovado' ? 'success' :
                                                                    item.status_bank === 'Reprovado' ? 'error' : 'info'
                                                            }
                                                            size="small"
                                                            variant="outlined"
                                                            label={item?.status_bank}/>
                                                    </TableCell>
                                                    <TableCell align="center">{item.user}</TableCell>
                                                    {is_superuser &&
                                                        <TableCell align="center">
                                                            {item.request_dictionary &&
                                                                <a href={item.request_dictionary} target="_blank" rel="noopener noreferrer">
                                                                    JSON
                                                                </a>
                                                            }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </DialogContent>
                                <DialogActions style={{ justifyContent: 'flex-start'}}>
                                    <Button onClick={() => setOpenRequest(false)} color="primary">
                                        Fechar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                        :
                        <Skeleton
                            variant="rounded"
                            height={700}
                            animation="pulse"
                        />
                    }

                </Card>

            </Box>
        </>
    )
}

export default LimitBankDetail;