import {
  TextField,
  IconButton,
  List,
  ListItem,
  Button,
  Box,
  CardHeader,
  Avatar,
  Divider,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Typography,
  Select,
  InputAdornment,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
  Fade,
  Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Add, AllInclusive, Article, Edit, Event, FilterList, Pix, ReceiptLong, Save} from "@mui/icons-material";
import SimpleText from "../../../components/Shared/SimpleText";
import Masks from "../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import moment from "moment";
import {api} from "../../../services/Main/Api";
import PaymentDate from "../Expense/components/ExpenseCreateUpdate/ExpensePayment/dependencies/PaymentDate";
import {DatePicker} from "@mui/x-date-pickers";
import SimpleSelector from "../../../components/Shared/SimpleSelector";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import Context from "../../../contexts/Context";
import {getBanks} from "../BankOperations/components/BankTransfer/Requisitions";

function DataInput(props) {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const params = useParams()
  const { dataProvider, sessionCheck } = useContext(Context)
  const { index, onChange, onRemove, id, pixCreate, deletePixById, getPix, ...dataProps } = props;
  const { pix_key, pix_key_value, name, bank, bank_branch, bank_account, bank_account_digit, bank_account_type, account_type_label  } = dataProps

  const [handleId, setHandleId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [bankNames, setBankNames] = useState(null)
  const [edit, setEdit] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)


  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  // function handlePixType(event) {
  //   onChange(index, { ...dataProps, pix_key_type: event.target.value, id })
  // }

  function handlePix(event) {
    onChange(index, { ...dataProps, pix_key_value: event.target.value, id,  })
  }

  function cpfValidation() {
    const cpfCnpj = dataProvider?.recipientCpfCnpj;
    return cpfCnpj && cpfCnpj.length <= 11;
  }

  function cnpjValidation() {
    const cpfCnpj = dataProvider?.recipientCpfCnpj;
    return cpfCnpj && cpfCnpj.length > 11;
  }


  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function bankNames(){
    getBanks(setBankNames)
  }, [])

  useEffect(function observeInputs() {
    const requiredKeys = ['pix_key_value'];
    const allKeysHaveValue = requiredKeys.every(key => !!dataProps?.[key]?.toString().trim());

    if (allKeysHaveValue) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [dataProps]);

  function isValidCPF(cpf) {
    if(typeof cpf !== "string") return false;
    cpf = cpf.replace(/[\s.-]*/gim, '');
    if(
          !cpf ||
          cpf.length != 11 ||
          cpf == "00000000000" ||
          cpf == "11111111111" ||
          cpf == "22222222222" ||
          cpf == "33333333333" ||
          cpf == "44444444444" ||
          cpf == "55555555555" ||
          cpf == "66666666666" ||
          cpf == "77777777777" ||
          cpf == "88888888888" ||
          cpf == "99999999999"
      ) {
        return false;
      }

      var sum = 0;
      var rest;

      for (var i=1; i<=9; i++) sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
      rest = (sum * 10) % 11;

      if ((rest == 10) || (rest == 11))  rest = 0;
      if (rest != parseInt(cpf.substring(9, 10)))
        return false;

      sum = 0;
      for (var i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
      rest = (sum * 10) % 11;

      if ((rest == 10) || (rest == 11))  rest = 0;
      if (rest != parseInt(cpf.substring(10, 11)))
        return false;
      return true;
    }

  function isValidCNPJ(cnpj) {

    if(typeof cnpj !== "string") return false
    cnpj = cnpj.replace(/[\s.-]*/gim, '')

    var pesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var base = 0
    var resultado = 0
    var resto = 0

    if (cnpj.length !== 14)
      return false

    for (var i = 0; i < 12; i++ ) {
      base += (cnpj[i] * pesos[i+1])
    }
    resto = (base % 11)

    if (cnpj[12] != (resto < 2 ? 0 : 11 - resto))
      return false

    base = 0
    resto = 0

    for (var i = 0; i < 13; i++) {
      base += (cnpj[i] * pesos[i])
    }
    resto = (base % 11)

    return cnpj[13] == (resto < 2 ? 0 : 11 - resto)
  }

  const formatPixKeyDisplay = (pixKey, cpf) => {
    let cleanedCpfOrCnpj = cpf?.replace(/[^a-zA-Z0-9]/g, "");
    let cleanedPixKey = pixKey?.replace(/[^a-zA-Z0-9]/g, "");

    // Se cleanedPixKey é igual a cleanedCpfOrCnpj verificamos o tamanho e formatamos como cpf ou cnpj.
    if(cleanedPixKey === cleanedCpfOrCnpj){
      if(cleanedPixKey?.length === 11){
        return Masks.cpf(pixKey ?? '');
      }
      else if (cleanedPixKey?.length === 14){
        return Masks.cnpj(pixKey ?? '');
      }
    }

    // Se cleanedPixKey não é igual a cleanedCpfOrCnpj, verificamos se cleanedPixKey é cpf ou cnpj válido.
    else {
      if (cleanedPixKey?.length === 11 && isValidCPF(cleanedPixKey)){
        return Masks.cpf(pixKey ?? '');
      }
      else if (cleanedPixKey?.length === 14 && isValidCNPJ(cleanedPixKey)){ // Você deve implementar a função isValidCNPJ similar a isValidCPF
        return Masks.cnpj(pixKey ?? '');
      }
      else if (cleanedPixKey?.length === 11 && (/^\d+$/.test(cleanedPixKey))) {
        return Masks.phone(pixKey ?? '');
      }
    }

    return pixKey;
  };

  return (
    <>

      <Box
        sx={{
          display: 'flex',
          borderRadius: '1rem',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >

        { id ?
          <Box
            sx={{
              justifyContent: 'space-between',
              // ml: '1rem',

              width: '100%',
            }}
          >

            <Box
              sx={{
                paddingX: '2rem',
                paddingTop: '0.5rem',
                mb: '0.3rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontSize: '18px'
                      }}
                  >
                    {formatPixKeyDisplay(pix_key_value, dataProvider.cpfCnpj)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '1rem'

                  }}
                >

                  <IconButton onClick={() => {
                    setHandleId(id)
                    setOpenDelete(true)
                    sessionCheck()
                  }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/*<Divider sx={{mb: '1rem'}}/>*/}

            <Box
              sx={{
                paddingX: '2rem',
                mb: '1rem'
              }}
            >

              <SimpleTypography
                label={`Favorecido`}
                directValue={name}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />

              <SimpleTypography
                label={`Banco`}
                directValue={bank?.name}
                labelStyles={{
                  fontWeight: 'bold'
                }}
                boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
                }}
              />

              {/*<SimpleTypography*/}
              {/*  label={'Agência'}*/}
              {/*  directValue={bank_branch}*/}
              {/*  labelStyles={{*/}
              {/*    fontWeight: 'bold'*/}
              {/*  }}*/}
              {/*  boxStyles={{*/}
              {/*    width: '100%',*/}
              {/*    mb: '0.5rem'*/}
              {/*  }}*/}
              {/*/>*/}

              {/*<SimpleTypography*/}
              {/*  label={'Conta'}*/}
              {/*  directValue={`${bank_account} - ${bank_account_digit}`}*/}
              {/*  labelStyles={{*/}
              {/*    fontWeight: 'bold'*/}
              {/*  }}*/}
              {/*  boxStyles={{*/}
              {/*    width: '100%',*/}
              {/*    mb: '0.5rem'*/}
              {/*  }}*/}
              {/*/>*/}
            </Box>
          </Box>

          :
          <>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                paddingX: '2rem'
              }}
            >

              { id ?
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '2rem'
                  }}
                >

                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{
                      color: 'primary.main'
                    }}
                  >
                    { 'Nova chave' }
                  </Typography>
                  {!id &&
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        // padding: '1rem'
                        mb: '1rem'

                      }}
                    >

                      <Button
                        onClick={() => {
                          pixCreate(params.id ?? dataProvider?.recipientId)
                        }}
                        disabled={!isSaveEnabled}

                      >
                        Salvar
                      </Button>

                      <Button onClick={() => {
                        onRemove(index)
                        setHandleId(null)
                      }}>
                        Cancelar
                      </Button>
                      {!edit && id &&
                        < IconButton onClick={() => onRemove(index)}>
                        <DeleteIcon/>
                        </IconButton>
                      }
                    </Box>
                  }
                </Box>
              }
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <TextField
                    label="Chave Pix"
                    fullWidth
                    size={'small'}
                    value={formatPixKeyDisplay(pix_key_value, dataProvider.cpfCnpj)}
                    // onChange={(e) => createRentRecipient(index, 'pix_key', e.target.value)}
                    onChange={handlePix}
                    style={{marginBottom: '10px'}}
                />


              </Box>
            </Box>

          </>
        }
        <SimpleDialog
          open={openDelete}
          setOpen={setOpenDelete}
          title={'Excluir chave Pix'}
          question={'Realmente quer excluir essa chave Pix?'}
          onClickConfirm={() => deletePixById(handleId)}
        />
      </Box>
    </>
  );
}

function RentRecipientPixSet(props) {
  const { dataProvider } = useContext(Context)
  const { data, onChange, setDeletePix, isLoading, pixCreate, getPix, deletePixById } = props;


  function handleDataChange (index, newData) {
    const newDataList = [...data]
    newDataList[index] = newData
    onChange(newDataList)
  }

  function handleDataRemove(index) {
    const newDataList = data.filter((_, i) => i !== index)
    const newDataDelete = data.filter((_, i) => i === index)
    const objToDelete = newDataDelete.find((obj) => obj.hasOwnProperty("id"))
    if (objToDelete) {
      setDeletePix(prevState => [...prevState, objToDelete])
    }
    onChange(newDataList)
  }

  function handleAddData() {
    const newDataList = [
      ...data, {
        description: '',
        value: '',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        account_type: 'PIX'
      }
    ]
    onChange(newDataList)
  }

  const dataFind = data?.find(e => e.id && e.account_type === 'PIX')


  return (
    <>
      <Box
        sx={{
          paddingX: '2rem',
          mt: '2rem'
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: '20px',
            mb: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Pix
            sx={{mr: '0.5rem'}}
          /> Chaves Pix
        </Typography>
        <Divider sx={{mb: '0.5rem'}}/>
      </Box>

      {!dataFind &&
        <Box
          sx={{
            marginY: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Alert
            severity="warning"
          >
            Não há nenhuma chave pix cadastrada
          </Alert>
        </Box>
      }

      <List>
      {data?.map((entry, index) => {
        if (entry.account_type === 'PIX') {
          return (
            <ListItem key={index} disableGutters>
              <DataInput
                index={index}
                id={entry.id}
                pix_key={entry.pix_key}
                pix_key_value={entry.pix_key_value}
                name={entry.name}
                bank={entry.bank}
                bank_branch={entry.bank_branch}
                bank_account={entry.bank_account}
                bank_account_digit={entry.bank_account_digit}
                bank_account_type={entry.bank_account_type}
                account_type_label={entry.account_type_label}
                account_type={entry.account_type}
                onChange={handleDataChange}
                onRemove={handleDataRemove}
                deletePixById={deletePixById}
                pixCreate={pixCreate}
                getPix={getPix}
              />
            </ListItem>
          );
        }
      })}
    </List>
    </>

  )
}

export default RentRecipientPixSet