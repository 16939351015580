import React, {
  useContext,
  useEffect
} from "react";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Grid,
  ToggleButtonGroup,
  ToggleButton, Fade, TextField
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import AccountReadInfo from "./AccountReadInfo";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import Masks from "../../../../../components/Shared/Masks";
import Context from "../../../../../contexts/Context";
import Ted from "./Ted";
import Pix from "./Pix";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const BankTransferBody = (
  {
    selectedAccount,
    setSelectedAccount,
    codeSent,
    check,
    setCheck,
    checkingAccounts,
    reqData,
    setReqData,
    bankNames,
    submitReq,
    account,
    setAccount,
    submitReqPix,
    buttonBehavior,
    buttonBehaviorPix,
  }) => {

  const  { filters, setFilters, dataProvider, setDataProvider, sessionCheck } = useContext(Context)

  const { operationType } = filters || {}

  function formatPixKey(selectedAccount) {
    const { pix_key_type, pix_key_value } = selectedAccount || {};

    switch (pix_key_type) {
      case '0':
        return Masks?.cpf(pix_key_value?.toString() ?? '');
      case '1':
        return Masks?.cnpj(pix_key_value?.toString() ?? '');
      case '3':
        return Masks?.phone(pix_key_value?.toString() ?? '');
      default:
        return pix_key_value?.toString() ?? '';
    }
  }

  useEffect(function hasLengthExpense(){
    operationType === 'transfer' && setFilters(prevState => ({...prevState, codeLength: !!reqData?.validation_code }))

  },[reqData?.validation_code])

  return(
    <>
          <CardContent
            sx={{
              paddingX: '2rem',
              mb: '1rem',
              width: '100%',

            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent={'space-between'}
            >
              {!dataProvider?.transferReq ?
                <Grid item xs={12}>

                  <AccountReadInfo/>

                  <ToggleButtonGroup
                    aria-label="Selecionar uma opção"
                    fullWidth
                    exclusive
                    value={filters?.transferType}
                    color={'primary'}
                    onChange={(e, value) => {
                      sessionCheck()
                      setCheck(false)
                      setReqData({ payment_date: moment(reqData?.payment_date).format() })
                      setAccount(null)
                      setSelectedAccount(null)
                      setFilters(prevState => ({...prevState, transferType: value}))
                    }}
                    sx={{mb: '1rem'}}
                  >
                    <ToggleButton
                      value={"ted"}
                      selected={filters?.transferType === 'ted' && dataProvider?.validation === true}
                    >
                      TED
                    </ToggleButton>
                    <ToggleButton
                      value={"pix"}
                      selected={filters?.transferType === 'pix' && dataProvider?.validation === true}
                    >
                      Pix
                    </ToggleButton>
                  </ToggleButtonGroup>


                {filters?.transferType === 'ted' && dataProvider?.validation === true ?
                <Fade
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >
                  <Box>
                    <Ted
                      account={account}
                      setAccount={setAccount}
                      selectedAccount={selectedAccount}
                      setSelectedAccount={setSelectedAccount}
                      checkingAccounts={checkingAccounts}
                      bankNames={bankNames}
                      codeSent={codeSent}
                      check={check}
                      setCheck={setCheck}
                      reqData={reqData}
                      setReqData={setReqData}
                      submitReq={submitReq}
                      buttonBehavior={buttonBehavior}
                    />
                  </Box>
                </Fade>
                : filters?.transferType === 'pix' && dataProvider?.validation === true &&
                <Fade
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >
                  <Box>
                    <Pix
                      account={account}
                      setAccount={setAccount}
                      selectedAccount={selectedAccount}
                      setSelectedAccount={setSelectedAccount}
                      checkingAccounts={checkingAccounts}
                      bankNames={bankNames}
                      codeSent={codeSent}
                      check={check}
                      setCheck={setCheck}
                      reqData={reqData}
                      setReqData={setReqData}
                      submitReqPix={submitReqPix}
                      buttonBehaviorPix={buttonBehaviorPix}
                    />
                  </Box>
                </Fade>
                }

                </Grid>
                :
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <SimpleTypography
                      label={'Tipo de Transferência'}
                      directValue={
                        filters?.transferType === 'ted' ? 'TED' :
                        filters?.transferType === 'pix' && 'Pix'
                      }
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '1rem'
                      }}
                    />

                    <SimpleTypography
                      label={'Favorecido'}
                      directValue={selectedAccount?.name}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '1rem'
                      }}
                    />

                    {selectedAccount?.pix_key_value &&
                      <SimpleTypography
                        label={'Chave'}
                        isLoading={!selectedAccount}
                        directValue={
                          formatPixKey(selectedAccount)
                        }
                        labelStyles={{
                          fontWeight: 'bold'
                        }}
                        boxStyles={{
                          width: '100%',
                          mb: '1rem'
                        }}
                      />
                    }

                    <SimpleTypography
                      label={'Banco'}
                      isLoading={!selectedAccount}
                      directValue={bankNames?.find((e) => e?.code === selectedAccount?.bank)?.name}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '1rem'
                      }}
                    />

                    {/*<SimpleTypography*/}
                    {/*  label={'Tipo'}*/}
                    {/*  isLoading={!selectedAccount}*/}
                    {/*  directValue={selectedAccount?.account_type_label ?? selectedAccount?.bank_account_type_label}*/}
                    {/*  labelStyles={{*/}
                    {/*    fontWeight: 'bold'*/}
                    {/*  }}*/}
                    {/*  boxStyles={{*/}
                    {/*    width: '100%',*/}
                    {/*    mb: '1rem'*/}
                    {/*  }}*/}
                    {/*/>*/}

                    {/*<SimpleTypography*/}
                    {/*  label={'Agência'}*/}
                    {/*  isLoading={!selectedAccount}*/}
                    {/*  directValue={`${selectedAccount?.bank_branch} ${selectedAccount?.bank_branch_digit ? `- ${selectedAccount?.bank_branch_digit}`  : ''}`}*/}
                    {/*  labelStyles={{*/}
                    {/*    fontWeight: 'bold'*/}
                    {/*  }}*/}
                    {/*  boxStyles={{*/}
                    {/*    width: '100%',*/}
                    {/*    mb: '1rem'*/}
                    {/*  }}*/}
                    {/*/>*/}

                    {/*<SimpleTypography*/}
                    {/*  label={'Conta'}*/}
                    {/*  isLoading={!selectedAccount}*/}
                    {/*  directValue={` ${selectedAccount?.bank_account} - ${selectedAccount?.bank_account_digit} `}*/}
                    {/*  labelStyles={{*/}
                    {/*    fontWeight: 'bold'*/}
                    {/*  }}*/}
                    {/*  boxStyles={{*/}
                    {/*    width: '100%',*/}
                    {/*    mb: '1rem'*/}
                    {/*  }}*/}
                    {/*/>*/}

                    <SimpleTypography
                      label={'Valor'}
                      isLoading={!selectedAccount}
                      directValue={Masks.money(reqData?.value ?? '')}
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      boxStyles={{
                        width: '100%',
                        mb: '2rem'
                      }}
                    />

                    {dataProvider?.reqError?.includes('Data para a transferência inválida') &&
                      <Box>
                      <Alert variant={'filled'} severity={'info'} sx={{ width: '100%', mt: '0.5rem' }}>
                        {`Escolha uma nova data`}
                      </Alert>
                      <DatePicker
                        sx={{ textAlign: 'center' }}
                        views={['year', 'month', 'day']}
                        label="Dia para transferência"
                        minDate={moment()}
                        inputProps={{
                          placeholder: 'DD/MM/AAAA'
                        }}
                        value={moment(reqData?.payment_date)}
                        onChange={(newValue) => setReqData((prevState) => ({
                          ...prevState,
                          payment_date: moment(newValue).format()
                        }))}
                        renderInput={
                          (params) => (
                            <TextField
                              {...params}
                              size={'small'}
                              sx={{ mb: 2 }}
                              helperText={null}
                              fullWidth
                              required
                            />
                          )
                        }
                      />
                    </Box>
                    }

                    {operationType === 'transfer' &&
                      <Button
                        sx={{
                          alignSelf: 'start'
                        }}
                        variant={'text'}
                        onClick={() => {
                          setSelectedAccount(null)
                          setCheck(false)
                          setFilters(prevState => ({...prevState, transferType: ''}))
                          setDataProvider(prevState => ({...prevState, validation: false}))
                        }}
                      >
                        <ArrowBackIos />{'Refazer'}
                      </Button>
                    }
                  </Box>
                </Grid>
              }
            </Grid>
          </CardContent>
    </>
  )
}

export default BankTransferBody

