import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {

  Receipt,
  ReceiptLong,
  Settings,

  Apartment,

  ViewModule,
  HomeWork,

  LocalAtm,

  ArticleOutlined,
  PersonOutline,
  Speed,
  PaymentsOutlined,
  RequestQuoteOutlined,
  Update,
  UpdateOutlined,
  Insights,
  Image,
} from '@mui/icons-material'
import logo from '../../assets/images/blocob3-logo-dark.png'
import logoReceita from '../../assets/images/logo-receita.png'
import logoText from '../../assets/images/blocob3-text-dark.png'
import CssBaseline from '@mui/material/CssBaseline'
import { useLocation } from 'react-router-dom'
import { Badge, Collapse, useTheme } from '@mui/material'
import {useContext, useEffect, useState} from "react";
import Context from "../../contexts/Context";
import { getCondoType } from '../../services/Main/AuthStorage'

const drawerWidth = 250

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const condoPaths = ['/avisos', '/manutencao', '/portaria', '/reserva', '/reclamacoes', '/despesas', '/condominio/imoveis']
const rentPaths = ['/contratos', '/alugueis/imoveis', '/beneficiarios', '/beneficiario', 'repasses']


function SubmenuItem({ text, icon, children, keyState, paths, functionMenu }) {
  const { dataProvider, setDataProvider } = useContext(Context)
  const [open, setOpen] = useState(false);
  const location = useLocation()

  function genericFunction() {
    if (paths.some(path => location.pathname.includes(path))) {
      setDataProvider(prevState => ({...prevState, [keyState]: true}));
    } else {
      setDataProvider(prevState => ({...prevState, [keyState]: true}));

    }
  }

  function optionSelected() {
    return paths.some(path => location.pathname.includes(path));
  }

  useEffect(function menuCheck(){
    genericFunction()
  },[location.pathname])


  const handleClick = () => {
    setDataProvider(prevState => ({ ...prevState, [keyState]: !dataProvider?.[keyState]}))
  };
  return (
    <>
      <ListItem disablePadding sx={{mt: '1rem'}}>
        <ListItemButton onClick={handleClick} selected={optionSelected()} >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
          {/*{dataProvider?.[keyState] ? <ExpandLess /> : <ExpandMore />}*/}
        </ListItemButton>
      </ListItem>
      <Collapse in={dataProvider?.[keyState]} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}



const Sidebar = (props) => {
  const { user, filters, setFilters } = useContext(Context)
  const {
    perm_transfer,
    perm_dashboard,
    perm_billboard,
    perm_booking,
    perm_complain,
    perm_expense,
    perm_maintenance,
    perm_management,
    perm_bank,
    is_superuser,
  } = user || {}
  const { condoMenu } = filters || {}

  const theme = useTheme()
  const { window } = props
  const location = useLocation()

  const container = window !== undefined ? () => window().document.body : undefined

  const activeRootStyle = {
    // color: 'primary.main',
    fontWeight: 'bold',
    bgcolor: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity),
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity)
    },
    '&.Mui-selected:hover': {
      backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.activatedOpacity)
    }
  }

  function renderCondoModule() {
    return (
      perm_billboard ||
      perm_expense ||
      perm_maintenance ||
      perm_management ||
      perm_complain ||
      perm_booking
    );
  }




  const sideBarContent = (
    <div>
      <DrawerHeader>
        <Box component={'div'} sx={{ display: 'flex', alignItem: 'center', pb: 5, pt: 1 }}>
          <img src={logo} alt="" style={{ height: 50 }}/>
          <img src={logoText} alt="" style={{ height: 24, marginLeft: 20, marginTop: 18 }}/>
        </Box>
      </DrawerHeader>
      <List>


        {perm_dashboard && <ListItem key={'dashboard'} disablePadding sx={{my: '1rem'}}>
          <ListItemButton href={'/'} selected={location.pathname === '/'}
                          sx={{ ...(location.pathname === '/' && activeRootStyle) }}>
            <ListItemIcon>
              <Speed sx={{ color: 'neutral.main' }}/>
            </ListItemIcon>
            <ListItemText primary={'Painel'}/>
          </ListItemButton>
        </ListItem> }


          <ListItem key={'invoice'} disablePadding sx={{my: '1rem'}}>
            <ListItemButton href={'/faturas'} selected={location.pathname.indexOf('/faturas') > -1}
                            sx={{...(location.pathname.indexOf('/faturas') > -1 && activeRootStyle)}}>
              <ListItemIcon>
                <Receipt sx={{color: 'neutral.main'}}/>
              </ListItemIcon>
              <ListItemText primary={'Faturas'}/>
            </ListItemButton>
          </ListItem>


        {getCondoType() === 'RENT' &&
          <SubmenuItem
            text={'Locação'}
            icon={<HomeWork sx={{color: 'neutral.main'}}/>}
            keyState={'rentMenu'}
            paths={rentPaths}
          >


              <ListItem key={'rent'} disablePadding sx={{my: '1rem'}}>
                <ListItemButton href={'/contratos'} selected={location.pathname.indexOf('/contratos') > -1}
                                sx={{...(location.pathname.indexOf('/contratos') > -1 && activeRootStyle)}}>
                  <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                    <ListItemIcon sx={{minWidth: 35}}>
                      <ArticleOutlined sx={{color: 'neutral.main'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Contratos'}/>
                  </Box>
                </ListItemButton>
              </ListItem>



            {perm_transfer && <ListItem key={'rent'} disablePadding sx={{my: '1rem'}}>
              <ListItemButton href={'/repasses'} selected={location.pathname.indexOf('/repasses') > -1}
                              sx={{...(location.pathname.indexOf('/repasses') > -1 && activeRootStyle)}}>
                <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                  <ListItemIcon sx={{minWidth: 35}}>
                    <LocalAtm sx={{color: 'neutral.main'}}/>
                  </ListItemIcon>

                  <ListItemText primary={'Repasses '}/>

                </Box>
              </ListItemButton>
            </ListItem> }


            {perm_transfer &&
              <ListItem key={'rent_recipient'} disablePadding sx={{my: '1rem'}}>
                <ListItemButton href={'/beneficiarios'} selected={location.pathname.indexOf('/beneficiarios') > -1}
                                sx={{...(location.pathname.indexOf('/beneficiarios') > -1 && activeRootStyle)}}>
                  <Box sx={{display: 'flex', mx: 2, alignItems: 'center' }}>
                    <ListItemIcon sx={{minWidth: 35}}>
                      <PersonOutline sx={{color: 'neutral.main'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Beneficiários'}/>
                  </Box>
                </ListItemButton>
              </ListItem>
            }

              <ListItem key={'Imóveis'} disablePadding sx={{my: '1rem'}}>
                <ListItemButton href={'/alugueis/imoveis'} selected={location.pathname.indexOf('/alugueis/imoveis') > -1}
                                sx={{...(location.pathname.indexOf('/alugueis/imoveis') > -1 && activeRootStyle)}}>
                  <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                    <ListItemIcon sx={{minWidth: 35}}>
                      <ViewModule sx={{color: 'neutral.main'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Imóveis'}/>
                  </Box>
                </ListItemButton>
              </ListItem>

             <ListItem key={'encargos'} disablePadding sx={{my: '1rem'}}>
              <ListItemButton href={'/encargos-locaticios'} selected={location.pathname.indexOf('/encargos-locaticios') > -1}
                              sx={{...(location.pathname.indexOf('/encargos-locaticios') > -1 && activeRootStyle)}}>
                <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                  <ListItemIcon sx={{minWidth: 35}}>
                    <RequestQuoteOutlined sx={{color: 'neutral.main'}}/>
                  </ListItemIcon>
                  <ListItemText primary={'Encargos'}/>
                </Box>
              </ListItemButton>
            </ListItem>
            <ListItem key={'reajustes'} disablePadding sx={{my: '1rem'}}>
              <ListItemButton href={'/reajustes'} selected={location.pathname.indexOf('/reajustes') > -1}
                              sx={{...(location.pathname.indexOf('/reajustes') > -1 && activeRootStyle)}}>
                <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                  <ListItemIcon sx={{minWidth: 35}}>
                    <UpdateOutlined sx={{color: 'neutral.main'}}/>
                  </ListItemIcon>
                  <ListItemText primary={'Reajustes'}/>
                </Box>
              </ListItemButton>
            </ListItem>
            { perm_bank === true &&
            <ListItem key={'relatorio-geral'} disablePadding sx={{my: '1rem'}}>
              <ListItemButton href={'relatorio-geral/'} selected={location.pathname.indexOf('relatorio-geral/') > -1}
                              sx={{...(location.pathname.indexOf('/relatorio-geral/') > -1 && activeRootStyle)}}>
                <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                  <ListItemIcon sx={{minWidth: 35}}>
                    <Insights sx={{color: 'neutral.main'}}/>
                  </ListItemIcon>
                  <ListItemText primary={'Relatório Geral'}/>
                </Box>
              </ListItemButton>
            </ListItem>
            }
            {is_superuser &&
            <ListItem key={'dimob'} disablePadding sx={{my: '1rem'}}>
              <ListItemButton href={'/dimob'} selected={location.pathname.indexOf('/dimob') > -1}
                              sx={{...(location.pathname.indexOf('/dimob') > -1 && activeRootStyle)}}>
                <Box sx={{display: 'flex', mx: 2, alignItems: 'center'}}>
                  <ListItemIcon sx={{minWidth: 35}}>
                    <img src={logoReceita} alt="Dimob" style={{width: '30px'}}/>
                  </ListItemIcon>
                  <ListItemText sx={{ml: 0.5}} primary={'Dimob'}/>
                  <Badge badgeContent={"Novo"} color="primary" size={'small'} variant={'string'}>&nbsp;&nbsp;&nbsp;</Badge>
                </Box>
              </ListItemButton>
            </ListItem>
            }

          </SubmenuItem>
        }
        {/*Seção de Condomínio*/}
        {getCondoType() === "CONDO" &&
          <SubmenuItem
            text={'Condomínio'}
            icon={<Apartment sx={{color: 'neutral.main'}}/>}
            keyState={'condoMenu'}
            paths={condoPaths}
          >

              <ListItem key={'expense'} disablePadding sx={{my: '1rem'}}>
                <ListItemButton href={'/despesas'} selected={location.pathname.indexOf('/despesas') > -1}
                                sx={{...(location.pathname.indexOf('/despesas') > -1 && activeRootStyle)}}>
                  <Box sx={{display: 'flex', mx: 2}}>
                    <ListItemIcon>
                      <ReceiptLong sx={{color: 'neutral.main'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Despesas'}/>
                  </Box>
                </ListItemButton>
              </ListItem>



              <ListItem key={'units'} disablePadding sx={{my: '1rem'}}>
                <ListItemButton href={'/condominio/imoveis'} selected={location.pathname.indexOf('/condominio/imoveis') > -1}
                                sx={{...(location.pathname.indexOf('/condominio/imoveis') > -1 && activeRootStyle)}}>
                  <Box sx={{display: 'flex', mx: 2}}>
                    <ListItemIcon>
                      <ViewModule sx={{color: 'neutral.main'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Imóveis'}/>
                  </Box>
                </ListItemButton>
              </ListItem>


            {/*{perm_management === true &&*/}
            {/*  <ListItem key={'portaria'} disablePadding sx={{my: '1rem'}}>*/}
            {/*    <ListItemButton href={'/portaria'} selected={location.pathname.indexOf('/portaria') > -1}*/}
            {/*                    sx={{...(location.pathname.indexOf('/portaria') > -1 && activeRootStyle)}}>*/}
            {/*      <Box sx={{display: 'flex', mx: 2}}>*/}
            {/*        <ListItemIcon>*/}
            {/*          <DoorBack sx={{color: 'neutral.main'}}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Portaria'}/>*/}
            {/*      </Box>*/}
            {/*    </ListItemButton>*/}
            {/*  </ListItem>*/}
            {/*}*/}

            {/*{perm_maintenance === true &&*/}
            {/*  <ListItem key={'manutencao'} disablePadding sx={{my: '1rem'}}>*/}
            {/*    <ListItemButton href={'/manutencao'} selected={location.pathname.indexOf('/manutencao') > -1}*/}
            {/*                    sx={{...(location.pathname.indexOf('/manutencao') > -1 && activeRootStyle)}}>*/}
            {/*      <Box sx={{display: 'flex', mx: 2}}>*/}
            {/*        <ListItemIcon>*/}
            {/*          <Engineering sx={{color: 'neutral.main'}}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Manutenções'}/>*/}
            {/*      </Box>*/}
            {/*    </ListItemButton>*/}
            {/*  </ListItem>*/}
            {/*}*/}



            {/*{perm_booking === true &&*/}
            {/*  <ListItem key={'reserva'} disablePadding sx={{my: '1rem'}}>*/}
            {/*    <ListItemButton href={'/reserva'} selected={location.pathname.indexOf('/reserva') > -1}*/}
            {/*                    sx={{...(location.pathname.indexOf('/reserva') > -1 && activeRootStyle)}}>*/}
            {/*      <Box sx={{display: 'flex', mx: 2}}>*/}

            {/*        <ListItemIcon>*/}
            {/*          <CalendarMonth sx={{color: 'neutral.main'}}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Reservar Área Comum'}/>*/}
            {/*      </Box>*/}
            {/*    </ListItemButton>*/}
            {/*  </ListItem>*/}
            {/*}*/}



            {/*{perm_billboard === true &&*/}
            {/*  <ListItem key={'avisos'} disablePadding sx={{my: '1rem'}}>*/}
            {/*    <ListItemButton href={'/avisos'} selected={location.pathname.indexOf('/avisos') > -1}*/}
            {/*                    sx={{...(location.pathname.indexOf('/avisos') > -1 && activeRootStyle)}}>*/}
            {/*      <Box sx={{display: 'flex', mx: 2}}>*/}
            {/*        <ListItemIcon>*/}
            {/*          <PriorityHigh sx={{color: 'neutral.main'}}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Avisos'}/>*/}
            {/*      </Box>*/}
            {/*    </ListItemButton>*/}
            {/*  </ListItem>*/}
            {/*}*/}






            {/*{perm_complain === true &&*/}
            {/*  <ListItem key={'reclamacoes'} disablePadding sx={{my: '1rem'}}>*/}
            {/*    <ListItemButton href={'/reclamacoes'} selected={location.pathname.indexOf('/reclamacoes') > -1}*/}
            {/*                    sx={{...(location.pathname.indexOf('/reclamacoes') > -1 && activeRootStyle)}}>*/}
            {/*      <Box sx={{display: 'flex', mx: 2}}>*/}
            {/*        <ListItemIcon>*/}
            {/*          <Announcement sx={{color: 'neutral.main'}}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Reclamações'}/>*/}
            {/*      </Box>*/}
            {/*    </ListItemButton>*/}
            {/*  </ListItem>*/}
            {/*}*/}

          </SubmenuItem>
        }

        { perm_bank === true &&
        <ListItem key={'charge'} disablePadding sx={{my: '1rem'}}>
          <ListItemButton href={'/cobranca'} selected={location.pathname.indexOf('/cobranca') > -1}
                          sx={{...(location.pathname.indexOf('/cobranca') > -1 && activeRootStyle)}}>
            <ListItemIcon>
              <ReceiptLong sx={{color: 'neutral.main'}}/>
            </ListItemIcon>

            <ListItemText primary={'Cobranças'}/> &nbsp;&nbsp;


          </ListItemButton>
        </ListItem>
        }
          <ListItem key={'settings'} disablePadding sx={{my: '1rem'}}>
            <ListItemButton href={'/configuracoes/contas'}
                            selected={location.pathname.indexOf('/configuracoes/contas') > -1}
                            sx={{...(location.pathname.indexOf('/configuracoes/contas') > -1 && activeRootStyle)}}>
              <ListItemIcon>
                <Settings sx={{color: 'neutral.main'}}/>
              </ListItemIcon>
              <ListItemText primary={'Configurações'}/>
            </ListItemButton>
          </ListItem>

      </List>
    </div>
  )

  return (
    <>
    {user &&
        <Box sx={{ display: 'flex' }} >
          <CssBaseline/>
          <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="Bloco B3">
            <Drawer
              container={container}
              variant="temporary"
              open={props.mobileMenu}
              onClose={props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              PaperProps={{
                sx: {
                  backgroundColor: 'secondary.main',
                  color: 'neutral.main'
                }
              }}>
              {sideBarContent}
            </Drawer>
            <Drawer
              variant="permanent"
              open
              sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              PaperProps={{
                sx: {
                  backgroundColor: 'secondary.main',
                  color: 'neutral.main'
                }
              }}>
              {sideBarContent}
            </Drawer>
          </Box>
        </Box>
      }
    </>
  )
}

export default Sidebar
