import {Box, Fade, MenuItem, Skeleton, TextField} from "@mui/material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import StateFilter from "../../../../../components/Filters/StateFilter";
import BlockFilter from "../../../../../components/Filters/BlockFilter";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Masks from "../../../../../components/Shared/Masks";
import {apiUser} from "../../../../../services/UnitUser/ApiUnitUser";
import {api} from "../../../../../services/Main/Api";
import axios from "axios";


const UnitInputs =
  ({
     reqData,
     setReqData,
     error,
     isLoading,
     rentUrl,
     loadBlock,
     defaultValue,
     blockOptions,
     filters,
   }) => {
    const params = useParams()
      const [cpfCnpjError, setCpfCnpjError] = useState(null);
      const [cepError, setCepError] = useState(null);
      // const [error, setError] = useState(false);

      function isValidCPF(cpf) {
          if(typeof cpf !== "string") return false;
          cpf = cpf.replace(/[\s.-]*/gim, '');
          if (
              !cpf ||
              cpf.length != 11 ||
              cpf == "00000000000" ||
              cpf == "11111111111" ||
              cpf == "22222222222" ||
              cpf == "33333333333" ||
              cpf == "44444444444" ||
              cpf == "55555555555" ||
              cpf == "66666666666" ||
              cpf == "77777777777" ||
              cpf == "88888888888" ||
              cpf == "99999999999"
          ) {
              return false;
          }
          var soma = 0;
          var resto;
          for (var i = 1; i <= 9; i++)
              soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
          resto = (soma * 10) % 11;
          if ((resto == 10) || (resto == 11))  resto = 0;
          if (resto != parseInt(cpf.substring(9, 10)) ) return false;
          soma = 0;
          for (var i = 1; i <= 10; i++)
              soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
          resto = (soma * 10) % 11;
          if ((resto == 10) || (resto == 11))  resto = 0;
          if (resto != parseInt(cpf.substring(10, 11) ) ) return false;
          return true;
      }

      function isValidCNPJ(cnpj) {

          if(typeof cnpj !== "string") return false
          cnpj = cnpj.replace(/[\s.-]*/gim, '')

          var pesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
          var base = 0
          var resultado = 0
          var resto = 0

          if (cnpj.length !== 14)
              return false

          for (var i = 0; i < 12; i++ ) {
              base += (cnpj[i] * pesos[i+1])
          }
          resto = (base % 11)

          if (cnpj[12] != (resto < 2 ? 0 : 11 - resto))
              return false

          base = 0
          resto = 0

          for (var i = 0; i < 13; i++) {
              base += (cnpj[i] * pesos[i])
          }
          resto = (base % 11)

          return cnpj[13] == (resto < 2 ? 0 : 11 - resto)
      }

      function validateCPFAndCNPJ(value) {
          const cleaned = ("" + value).replace(/\D/g, "");
          const isCpf = cleaned.length === 11 && isValidCPF(cleaned);
          const isCnpj = cleaned.length === 14 && isValidCNPJ(cleaned);

          return isCpf || isCnpj;
      }

      const handleCpfCnpjChange = (value) => {

          let cleanValue = value.replace(/\D/g, "");

          if (cleanValue.length > 11) {
              value = Masks?.cnpj(cleanValue);
          } else if (cleanValue.length > 0) {
              value = Masks?.cpf(cleanValue);
          }

          setReqData(prevData => ({ ...prevData, cpf: value }));

          if (cleanValue.length >= 11) {
              if (!validateCPFAndCNPJ(cleanValue)) {
                  setCpfCnpjError('CPF ou CNPJ inválido');
              } else {
                  setCpfCnpjError(null);
              }
          } else {
              setCpfCnpjError(null);
          }
      }

      useEffect(() => {
          let cep = reqData?.cep;
          cep = cep?.replace(/\D/g,'');
          if (cep?.length === 8) {
              axios.get(`https://opencep.com/v1/${cep}.json`)
                  .then(response => {
                      setReqData(prevState => ({
                          ...prevState,
                          street: response.data.logradouro,
                          district: response.data.bairro,
                          city: response.data.localidade,
                          state: response.data.uf
                      }));
                  }).catch(error => {
                  console.log(error)
              })
          } else {
              setReqData(prevState => ({
                  ...prevState,
                  street: '',
                  district: '',
                  city: '',
                  state: ''
              }));
          }
      }, [reqData?.cep]);

    return (
      <Box>
        { !isLoading ?
          <TextField
            select
            required
            label={'Tipo do Imóvel'}
            value={reqData?.unit_type ?? ''}
            size={'small'}
            onChange={(e) => setReqData(prevState => ({...prevState, unit_type: e.target.value}))}
            sx={{mb: '1rem', width: '100%'}}

          >
            <MenuItem value="apartment">Apartamento</MenuItem>
            <MenuItem value="house">Casa</MenuItem>
            <MenuItem value="commercial_house">Casa comercial</MenuItem>
            <MenuItem value="pavilion">Pavilhão</MenuItem>
            <MenuItem value="commercial_room">Sala comercial</MenuItem>
            <MenuItem value="batch">Lote</MenuItem>
            <MenuItem value="shed">Galpão</MenuItem>
            <MenuItem value="hall">Salão</MenuItem>
            <MenuItem value="shop">Loja</MenuItem>
            <MenuItem value="kitnet">Kitnet</MenuItem>
            <MenuItem value="two-story-house">Casa Sobrado</MenuItem>
            <MenuItem value="single-house">Casa Térrea</MenuItem>
            <MenuItem value="duplex-house">Casa Duplex</MenuItem>
            <MenuItem value="garage">Garagem</MenuItem>
          </TextField>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={39.964}
            sx={{mb: '1rem'}}
          />
        }


        {/*</Box>*/}


        <SimpleText
          label={'Nome do Locatário'}
          required
          state={reqData}
          setState={setReqData}
          keyName={'name'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

          <TextField
              label={'CPF / CNPJ do Morador'}
              required
              state={reqData}
              setState={setReqData}
              keyName={'name'}
              value={reqData?.cpf}
              onChange={(e) => handleCpfCnpjChange(e.target.value)}
              error={!!cpfCnpjError}
              helperText={cpfCnpjError}
              fullWidth
              size={'small'}
              sx={{ mb: '1rem'}}
          />

        <SimpleText
          label={'E-mail'}
          state={reqData}
          setState={setReqData}
          keyName={'email'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'Telefone'}
          phone
          state={reqData}
          setState={setReqData}
          keyName={'phone'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />
          {rentUrl &&
          <SimpleText
              label={'CEP'}
              cep
              state={reqData}
              setState={setReqData}
              keyName={'cep'}
              isLoading={isLoading}
              styles={{ mb: '1rem'}}
              error={error}

          />
          }
        {rentUrl &&
          <SimpleText
            label={'Rua'}
            state={reqData}
            setState={setReqData}
            keyName={'street'}
            isLoading={isLoading}
            error={error}
            styles={{mb: '1rem'}}
          />
        }

        <SimpleText
          label={'Número'}
          required
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'number'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        { rentUrl &&
          <>

            <SimpleText
              label={'Bairro'}
              state={reqData}
              setState={setReqData}
              keyName={'district'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'Complemento'}
              state={reqData}
              setState={setReqData}
              keyName={'complement'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <Box
              sx={{
                display: 'flex'
              }}
            >

              <SimpleText
                label={'Cidade'}
                state={reqData}
                setState={setReqData}
                keyName={'city'}
                isLoading={isLoading}
                error={error}
                styles={{
                  mr: '1rem',
                  mb: '1rem'
                }}
              />

              <StateFilter
                label={'Estado'}
                state={reqData}
                setState={setReqData}
                keyName={'state'}
                styles={{ mb: '1rem'}}
              />

            </Box>
          </>
        }



        <SimpleText
          label={'m²'}
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'square_meter'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'Área Construída m²'}
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'building_meter'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        {!rentUrl &&
          <SimpleText
            label={'Fração %'}
            decimals={true}
            state={reqData}
            setState={setReqData}
            keyName={'percent_meter'}
            isLoading={isLoading}
            error={error}
            styles={{mb: '1rem'}}
          />
        }

        {loadBlock === true && params?.id &&
          <BlockFilter
            label={'Blocos'}
            state={reqData}
            setState={setReqData}
            errors={error}
            isLoading={isLoading}
            keyName={'block'}
            defaultValue={defaultValue}
            styles={{
              mb: '1rem'
            }}
          />
        }

        {loadBlock === true && blockOptions?.length > 0 && !params?.id &&
          <BlockFilter
            label={'Blocos'}
            state={reqData}
            setState={setReqData}
            errors={error}
            isLoading={isLoading}
            keyName={'block'}
            defaultValue={defaultValue}
            styles={{
              mb: '1rem'
            }}
          />
        }

        <Fade
          in={filters?.renter}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Nome do proprietário'}
              required={filters?.renter}
              state={reqData}
              setState={setReqData}
              keyName={'owner_name'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />


            <SimpleText
              label={'Telefone do proprietário'}
              phone
              state={reqData}
              setState={setReqData}
              keyName={'owner_phone'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'E-mail do proprietário'}
              state={reqData}
              setState={setReqData}
              keyName={'owner_email'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />
          </Box>

        </Fade>


      </Box>

    )
  }

export default UnitInputs