import * as React from 'react'
import { useState, useContext } from 'react'
import {
    IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material'
import { MoreVert, TaskAltOutlined} from '@mui/icons-material'
import { api } from '../../../services/Main/Api'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import SignedRecipentTransferMakeOpen from "./SignedRecipentTransferMakeOpen";
import SignedRecipentTransferMakePaid from "./SignedRecipentTransferMakePaid";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const SignedRecipientTransferMakeMenu = ({rentRecipientTransfer, handleManualPayment}) => {

    const {setLoadingModal, user} = useContext(Context)
    const [isOpen, setIsOpen] = useState(false)
    const [openByStatus, setOpenByStatus] = useState({finalized: false, remake: false, create: false, open: false})
    const [anchor, setAnchor] = useState(null)
    const [reason, setReason] = useState('');
    const {enqueueSnackbar} = useSnackbar()
    const { is_superuser } = user || {}

    const handleCloseConfirmation = (e) => {
        setOpenByStatus({finalized: false, remake: false, create: false, open: false})
        setIsOpen(false)
    }

    const handleMakeItFinalized = (e) => {
        api.put(`signed-recipient-transfer/${rentRecipientTransfer?.id}/`, {status: "FINALIZED", finalized_by: "MANUAL", reason: reason}).then(response => {
            if (response.status === 200) {
                handleManualPayment()
                enqueueSnackbar("Repasse marcado como finalizado com sucesso!", {variant: 'success'})
            }
        }).catch(response => {
            enqueueSnackbar("Não foi possível processar esta repasse", {variant: "error"})
        }).finally(() => {
            handleCloseConfirmation(e)
            setLoadingModal(false)
            setReason('')
        })
    }

    const resendWhatsappMensage = (e) => {
        api.post(`signed-recipient-transfer/${rentRecipientTransfer?.id}/`, {}).then(response => {
            if (response.status === 200) {
                handleManualPayment()
                enqueueSnackbar("Repasse marcado como finalizado com sucesso!", {variant: 'success'})
            }
        }).catch(response => {
            enqueueSnackbar("Não foi possível processar esta repasse", {variant: "error"})
        }).finally(() => {
            handleCloseConfirmation(e)
            setLoadingModal(false)
            setReason('')
        })
    }
    const handleMakeItOpen = (e) => {
        setIsOpen(false)
        setLoadingModal(true)
        api.put(`signed-recipient-transfer/${rentRecipientTransfer?.id}/`, {status: "OPEN", finalized_by: null}).then(response => {
            handleManualPayment()
            enqueueSnackbar("Repasse aberto com sucesso!", {variant: 'success'})
        }).catch(response => {
            enqueueSnackbar("Não foi possível abrir esta repasse", {variant: "error"})
        }).finally(() => {
            handleCloseConfirmation(e)
            setLoadingModal(false)
        })
    }

console.log('rentRecipientTransfer', rentRecipientTransfer)
    return (
        <>

            <IconButton
                onClick={(e) => {
                    e.stopPropagation()
                    setAnchor(e.currentTarget)
                    setIsOpen(true)
                }}>
                <MoreVert/>
            </IconButton>

            <Menu
                open={isOpen ??!!isOpen}
                anchorEl={anchor && anchor}
                onClose={(e) => {
                    e.stopPropagation()
                    setIsOpen(false)
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >

                {(rentRecipientTransfer?.status?.id !== "PAID" && rentRecipientTransfer?.status?.id !== 'FINALIZED') || rentRecipientTransfer?.status?.id === 'ERROR' ?
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenByStatus((prevState) => ({...prevState, finalized: !openByStatus.finalized}))
                        }}
                        sx={{color: 'text.secondary'}}
                    >
                        <ListItemIcon>
                            <TaskAltOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Baixa Manual" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                    : null
                }

                {(rentRecipientTransfer?.status?.id === 'FINALIZED' || rentRecipientTransfer?.status?.id === 'ERROR') &&
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenByStatus((prevState) => ({...prevState, open: !openByStatus.open}))
                        }}
                        sx={{ color: 'text.secondary' }}
                    >
                        <ListItemIcon>
                            <TaskAltOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Abrir Repasse" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                }
                {is_superuser &&
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        resendWhatsappMensage(e)
                    }}
                    sx={{ color: 'text.secondary' }}
                >
                    <ListItemIcon>
                        <WhatsAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Enviar Whatsapp" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                }
            </Menu>

            <SignedRecipentTransferMakePaid
                openByStatus={openByStatus}
                handleCloseConfirmation={handleCloseConfirmation}
                handleMakeItFinalized={handleMakeItFinalized}
                reason={reason}
                setReason={setReason}
            />

            <SignedRecipentTransferMakeOpen
                openByStatus={openByStatus}
                handleCloseConfirmation={handleCloseConfirmation}
                handleMakeItOpen={handleMakeItOpen}
            />


        </>
    )
}

export default SignedRecipientTransferMakeMenu
